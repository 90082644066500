import React from "react"
import "modern-css-reset"
import Header from "../components/header"
import ContactForm from "../components/contactForm"
import 'typeface-josefin-sans'
import Footer from "../components/footer"

export default function Home() {
  return (
    <div>
      <Header />
      <ContactForm />
      <Footer />
    </div>
  )
}
