import React from "react"
import styles from "../components/contactForm.module.css"


export default function ContactForm() {
  return (
    <contactForm>
      <div className={styles.layout}>
        <h2>お問い合わせ</h2>
        <div className={styles.formBox}>
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div>
              <label>
                <p>お名前</p>
                <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                <p>フリガナ</p>
                <input type="text" name="furigana" required/>
              </label>
            </div>
            <div>
              <label>
                <p>会社名</p>
                <input type="text" name="company" required />
              </label>
            </div>
            <div>
              <label>
                <p>E-mail</p>
                <input type="text" name="e-mail" required/>
              </label>
            </div>
            <div>
              <label>
                <p>お問い合わせ内容</p>
                <textarea name="message" required></textarea>
              </label>
            </div>
            <p className={styles.buttonBox}>
                <button type="submit" className={styles.submit_text}>送信</button>
            </p>
          </form>
        </div>
      </div>
    </contactForm>
  )
}
